import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/v1/Footer.js';
import { Footer as FooterV2 } from '../components/v2/Footer.js';
import Header from '../components/v1/Header.js';
import { Header as HeaderV2 } from '../components/v2/Header.js';
import ParcelInfo from '../components/v1/PacrcelInfo.js';
import KnowMore from '../components/v1/KnowMore.js';
import EmailForm from '../components/v1/EmailForm.js';
import { getParcelInfo }  from '../services/index.js';
import { getBaseURL } from '../services/parcels.js'
import { ReactComponent as Chevron } from './../assets/arrow_forward_ios.svg'
import { ReactComponent as HomePrimary } from './../assets/home_primary.svg'
import TrackingPixel from '../components/v2/trackingPixel.js';
import CircularProgress from '@mui/material/CircularProgress';

export function ParcelChoices (){

  let { parcelId } = useParams();

  const [parcel, setParcel] = useState({});

  useEffect(() => {

    const datafetch = async () => {

        let data = await getParcelInfo(parcelId);
        process.env.REACT_APP_DEBUG === true && console.log(data)

        if(data.status === 200 ) {
          if(data.results.clientFrontWebVersion	!== '1'){
            data.results.clientFrontWebVersion = '2'; //If the clientFrontWebVersion is not 1 (the old interface) then it is set to 2 by default(the new interface)
          }
          setParcel(data.results);
        }

    }

    datafetch();

  }, [parcelId]);

  let style = {
    parcelChoiceType1 : {
        backgroundColor: '#1E1E1E',
        height: 'auto',
        width: '100%',
        margin: '0',
        padding: '0',
    },
    parcelChoiceType2 : {
      backgroundColor: '#FFFFFF',
      height: 'auto',
      width: '100%',
      margin: '0',
      padding: '0',
    },
    mainContent : {
      marginBottom: '10rem',
    },
    button: {
      borderRadius: '30px',
      padding: '0.3em 0.8em 0.3em 0.8em',
      boxShadow: '0px 0px 16px 0px #878787',
      fontSize: '.9rem',
      textAlign: 'left',
  },
  icon: {
    marginRight: '1rem',
    width: '3rem',
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  description: {
    fontWeight: 300,
  },
}

  const onClik = (path) => {

    const baseURL = getBaseURL();
    const url = baseURL+path+`/${parcel.id}`
    window.open(url, '_self');

  }

  const renderContent = () => {
    if (parcel && parcel.clientActionsList && parcel.clientFrontWebVersion === '2') {
      const clientActionsList = parcel.clientActionsList.map((action, index) => {
          return (<div className="row justify-content-center text-left my-2 mx-2" key={index}>
            <button type="submit" onClick={() => onClik(action.url)} className="btn btn-secondary mt-4 text-black d-flex justify-content-between align-items-center" style={style.button}>
              {
                action.icon? <img src={require(`./../assets/${action.icon}.svg`)} alt="icon" className='icon' style={style.icon} /> : <div style={style.icon}/>
              }
              <div className="textContent" style={style.textContent}>
              <b>{action.name}</b><br/>
              <div style={style.description}>{action.description}</div>
              </div>
              <Chevron />
            </button>
          </div>)
        }
      );
      return (
          <div className="parcelChoice" style={style.parcelChoiceType2}>
              <HeaderV2 content="identifiant" tripCount={parcel.usage} />
              <div className="w-100 text-black p-4" style={style.mainContent}>
                <div style={style.subtitle}>Aide-le à atteindre les 100 et + utilisations en lui donnant une nouvelle vie !</div>
                {clientActionsList}
              </div>
              <FooterV2 content="identifiant" tripCount={parcel.usage} tag={parcel.tag}/>
              <TrackingPixel clientID={"0000000000"} event={"b2c_pageview"} parcelID={parcel.id}/>
          </div>
      );
    } else if (parcel && parcel.clientActionsList && parcel.clientFrontWebVersion === '1'){
      return (
        <div className="parcelChoice" style={style.parcelChoiceType1}>
          <Header parcel={parcel} />
          <ParcelInfo parcel={parcel} />
          <KnowMore parcel={parcel} />
          <EmailForm />
          <Footer marginTop="15%" />
        </div>
      );
    } else if (parcel && parcel.clientActionsList ){
      //While waiting for the data to be fetched, a loading spinner is displayed
      return (
      <div className='d-flex h-100 justify-content-center align-items-center '>
        <CircularProgress />
      </div>
      );
    } else {
      return (
        <div className="parcelChoice" style={style.parcelChoiceType2}>
            <HeaderV2 content="identifiant" tripCount={parcel.usage} />
            <div className="w-100 text-black p-4" style={style.mainContent}>
              <div style={style.subtitle}>Aide-le à atteindre les 100 et + utilisations en lui donnant une nouvelle vie !</div>
              <div className="row justify-content-center text-left my-2 mx-2" key={1}>
                <button type="submit" onClick={() => onClik('retours/emballage')} className="btn btn-secondary mt-4 text-black d-flex justify-content-between align-items-center" style={style.button}>
                    <HomePrimary alt="icon" className='icon' style={style.icon} />
                  <div className="textContent" style={style.textContent}>
                    <b>Restituer un MOBIUSpack</b><br/>
                    <div style={style.description}>J'ai de l'impact en rendant gratuitement mes colis</div>
                  </div>
                  <Chevron />
                </button>
              </div>

            </div>
            <FooterV2 content="identifiant" tripCount={'1'} tag={parcel.tag}/>
            <TrackingPixel clientID={"0000000000"} event={"b2c_pageview"} parcelID={parcel.id}/>
        </div>
    );
    }

  }

  return (
      renderContent()
  );

}
